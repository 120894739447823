import * as types from "../types";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";
import { hasCookie, getCookie } from "cookies-next";

export const fetchFavourites = (userId) => async (dispatch) => {
    if (hasCookie("authuser")) {
        const { access_token } = JSON.parse(getCookie("authuser")) || "";

        // loggedIn user authorized token header
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
        };
        try {
            const res = await axios.get(
                `${serverAPI}/favorites/user/${userId}`,
                headers
            );
            dispatch({
                type: types.GET_FAVOURITES,
                payload: res.data,
            });
        } catch (error) {
            alert(error);
        }
    } else {
        dispatch({
            type: types.GET_FAVOURITES,
            payload: [],
        });
    }
};

export const addFavourite = (propId, ) => async (dispatch) => {
    const { access_token } = JSON.parse(getCookie("authuser")) || "";
    // loggedIn user authorized token header
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        let formData = JSON.stringify({ property_list_id: propId });
        const res = await axios.post(`${serverAPI}/favorites/`, formData, {
            headers
        });
        dispatch({
            type: types.ADD_FAVOURITE,
            payload: res.data,
        });
    } catch (error) {
        alert(error);
    }
};

export const removeFavourite = (favId, ) => async (dispatch) => {
    const { access_token } = JSON.parse(getCookie("authuser")) || "";
    // loggedIn user authorized token header
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const res = await axios.delete(`${serverAPI}/favorites/${favId}`, {
            headers,
        });
        dispatch({
            type: types.DEL_FAVOURITE,
            payload: favId,
        });
    } catch (error) {
        alert(error);
    }
};
