import { useState, useEffect } from "react";
import { ListingType } from "../envConfig";

export default function useLoanCalculator(propertyValue, listingType) {
    const [loanAmount, setLoanAmout] = useState(propertyValue);
    const [years, setYears] = useState(listingType === ListingType[1] ? 1 : 25);

    const [interestRate, setInterestRate] = useState(4.99);

    const monthlyRate =
        Number(interestRate) > 0 ? Number(interestRate) / 1200 : 0;

    const loanDurationInMonths = years * 12;

    const rateToPower = (1 + monthlyRate) ** loanDurationInMonths;

    const denominator = rateToPower - 1;

    const rightSide =
        (monthlyRate * rateToPower) / (denominator > 0 ? denominator : 1);

    const monthlyPayments =
        rightSide > 0
            ? (loanAmount * rightSide).toFixed(2)
            : (loanAmount / 12).toFixed(2);

    // mortgage formulA= M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]

    const changeLoanAmount = ([min, max]) => {
        setLoanAmout(max);
    };

    const changeYears = ([min, max]) => {
        setYears(max);
    };

    const changeInterestRate = (e) => {
        const { value } = e.target;
        if (isNaN(value) || Number(value) > 100) {
            return;
        }

        setInterestRate(value);
    };

    useEffect(() => {
        changeLoanAmount([0, propertyValue]);
    }, [propertyValue]);

    return {
        monthlyPayments,
        changeInterestRate,
        changeLoanAmount,
        changeYears,
        interestRate,
        setInterestRate,
        years,
        setYears,
        loanAmount,
        setLoanAmout,
    };
}
