import { useEffect } from "react";
import { useSelector } from "react-redux";
import List from "./List";
import SlickSliderDeal from "./SlicksliderDeal";
import { fetchFavourites } from "../redux/actions/user";
import { useDispatch } from "react-redux";
import { fetchDeal } from "../redux/actions/property";

const Deals = () => {
    const dispatch = useDispatch();
    const property = useSelector((state) => state.property.dealOfWeek);
    const fav = useSelector((state) => state.user.favourites);
    const { isLoggedIn, loggedInUser } = useSelector((state) => state?.user);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchFavourites(loggedInUser?.id));
        }
        !property?.length && dispatch(fetchDeal());
    }, [dispatch, isLoggedIn, loggedInUser?.id, property?.length]);

    return !property?.length ? (
        <></>
    ) : (
        <div className=" 4xs:pb-4 2xl:pb-8 mt-5">
            <div
                className="lg:container  lg:mx-auto 2xl:pt-10 flex justify-start  
            mx-10  pt-8 pb-3  3xl:max-w-[1500px]"
            >
                <h3 className="text-navy font-Lexend ">
                    <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl">
                        Deals
                    </span>
                    <span className="4xs:text-2xl 3xl:text-3xl">
                        {""} of the Week
                    </span>
                </h3>
            </div>
            <div className="container mx-auto  ">
                <SlickSliderDeal>
                    {property.length > 0 &&
                        fav &&
                        property
                            .filter((p) => p.status === "Active")
                            .filter((pro) => pro.dealOfWeek === true)
                            .map(
                                (prop, index) =>
                                    index < 5 && (
                                        <List
                                            key={prop.id}
                                            property={prop}
                                            isFav={fav
                                                .map((d) => d.property_list_id)
                                                .includes(prop.id)}
                                        />
                                    )
                            )}
                </SlickSliderDeal>
            </div>

            {/* <div className='container  lg:mx-auto pt-10 flex justify-end xl:w-[75%]'>
        <div className='text-gray-500 flex items-center   pr-10  '>
          <button className='px-1 py-1 border-4 border-gray-500 hover:border-white rounded-full mr-2 hover:text-white' onClick={() => slider?.current?.slickPrev()}> <i className="fa-solid fa-arrow-left text-lg self-center mx-2"></i> </button>
          <button className='pl-2 pr-5 py-1 border-4 border-gray-500 hover:border-white rounded-full font-bold flex items-center hover:text-white' onClick={() => slider?.current?.slickNext()}> <i className="fa-solid fa-arrow-right text-lg self-center mx-1"></i><span>Next</span></button>
        </div>
      </div> */}
            {/* <DealsCarousal property={property.filter(p=>p.listingType===type)} breakPoints={breakPoints} totalPages={totalPages}/> */}
        </div>
    );
};

export default Deals;
