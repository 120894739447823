import Slider from "react-slick";
import { useState, useEffect, useRef } from "react";
export default function SlickSliderDeal ( { children } )
{
    const slider = useRef( null );
    const breakPoints = [
        {
            breakpoint: 1536,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2,
            },
        },

        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            },
        },
    ];

    const settings = {
        dots: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: breakPoints,
        arrows: false,
        centerMode: true,
        infinite: children?.length > 4,
        slidesToShow: 4,
        variableWidth: true,
    };

    return (
        <>
            <Slider ref={ slider } { ...settings }>
                { children }
            </Slider>
            <div className="container  lg:mx-auto flex justify-end 4xs:pr-3 2xl:pr-20 ixs:pr-7 xl:pr-9">
                <div className="flex items-cend justify-end ">
                    <button
                        className="shadow-customnavy   rounded-2xl font-bold flex items-center h-12 w-12 justify-center mr-4 hover:bg-navy    text-night hover:text-[#FFDEAB] "
                        onClick={ () => slider?.current?.slickPrev() }
                    >
                        { " " }
                        <i className="fa-solid fa-arrow-left text-lg self-center mx-2 "></i>{ " " }
                    </button>
                    <button
                        className=" shadow-customnavy hover:bg-navy   rounded-2xl font-bold 
                        h-12 w-12 flex items-center justify-center text-night hover:text-[#FFDEAB]"
                        onClick={ () => slider?.current?.slickNext() }
                    >
                        { " " }
                        <i className="fa-solid fa-arrow-right text-lg self-center mx-1 "></i>
                  
                    </button>
                </div>
            </div>
        </>
    );
}
